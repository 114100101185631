export const PROJECT_NAME = 'Foodorm';
export const BASE_URL = 'https://testing.transcaregroup.com';
export const WEBSITE_URL = 'https://foodorm.com.ng';
export const api_url = BASE_URL + '/api/v1/';
export const api_url_admin = BASE_URL + '/api/v1/dashboard/admin/';
export const export_url = BASE_URL + '/storage/';
export const IMG_URL = BASE_URL + '/storage/images/';
export const MAP_API_KEY = 'AIzaSyBkhQv6yn0yACxpmNoKLaKCbKdPn4WC0KU';
export const VAPID_KEY =
  'BJKe0-BzRtj-ihBEoyV14XSy0OMZAAV_WUgDKE6g44L2F7oTv5PAMo3L9SNBMap8t169qjZ_dXmbUCyZSZajscc';
export const STRIPE_PUBLISHABLE_KEY =
  'pk_test_51LaGXyLrMlc0ddAAYc8wxwq8eFj9HbgpvValjxRtg2OZzTJDwFz0ZmATL1TOqAWUFBM3CeHbI5Cny71MzkrAXRxa00XTCJArPr';
export const PAYPAL_KEY =
  'ARKhb_cfiyLAGikamzpXnWEiynGxcGeOkmuxWQ9i1CUztdQy3F_UT4C7vbtR6sfPQ3kxecBi_NL_UEDF';
export const PAYSTACK_KEY = 'pk_test_2ec2bc5ed445385489385b2fce6cddfbfb265914';
export const RAZORPAY_KEY = 'rzp_test_TMPwvQpYGAIMbU';
export const StripeApi = 'https://foodorm.com.ng/api/create-stripe-session';
export const RazorpayApi = 'https://foodorm.com.ng/api/create-razorpay-session';

export const API_KEY = 'AIzaSyBkhQv6yn0yACxpmNoKLaKCbKdPn4WC0KU';
export const AUTH_DOMAIN = 'marketruns-b6060.firebaseapp.com';
export const PROJECT_ID = 'marketruns-b6060';
export const STORAGE_BUCKET = 'marketruns-b6060.appspot.com';
export const MESSAGING_SENDER_ID = '502285720206';
export const APP_ID = '1:502285720206:web:a7d3784365eccfabea07df';
export const MEASUREMENT_ID = 'G-T1K6ZXME29';
export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/svg',
];
